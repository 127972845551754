import { DEFAULT_COUNTRY_CODE } from "src/localization/localization"

export const routes = {
  home: "/",
  formatCollection: (handle: string) => `/collections/${handle}`,
  formatCategory: (handle: string) => `/categories/${handle}`,
  formatProduct: (handle: string | null) => (handle ? `/products/${handle}` : "#"),
  productsCanonical: "/products",
  search: "/search",
  searchResultsCanonical: "results",
  cart: "/cart",
  checkoutRoot: "/checkout",
  thankYouRoot: "/thank-you",
  formatThankYou: (cartOrOrderId: string) => `/thank-you/${cartOrOrderId}`,
  accountRoot: "/account",
  accountAddresses: "/account/addresses",
  accountOrders: "/account/orders",
  formatAccountOrder: (orderId: string) => `/account/orders/${orderId}`,
  accountProfile: "/account/profile",
  infoTermsAndConditions: "/agb",
  infoRevocation: "/widerruf",
  infoLegalNotice: "/impressum",
  infoPrivacyPolicy: "/datenschutz",
  infoCookies: "/cookies",
} as const

export function formatLocalizedPath(countryCode: string, path: string) {
  const localizedHref = countryCode === DEFAULT_COUNTRY_CODE ? path : `/${countryCode}${path}`

  return localizedHref
}
