import { MoneyAmount } from "@medusajs/medusa"
import { getLocaleForCountryCode } from "src/localization/localization"
import { Region, Variant } from "src/types/medusa"
import { DetailVariantData, RegionData } from "../types/global"
import { isEmpty } from "./isEmpty"

const findCheapestRegionPrice = (variants: Variant[], regionId: string) => {
  const regionPrices = variants.reduce((result, variant) => {
    if (!variant.prices) {
      return result
    }

    const price = variant.prices.find((price) => price.region_id === regionId)
    if (price) {
      result.push(price)
    }

    return result
  }, [] as MoneyAmount[])

  if (!regionPrices.length) {
    return undefined
  }

  //find the price with the lowest amount in regionPrices
  const cheapestPrice = regionPrices.reduce((result, price) => {
    if (result.amount > price.amount) {
      return price
    }

    return result
  })

  return cheapestPrice
}

const findCheapestCurrencyPrice = (variants: Variant[], currencyCode: string) => {
  const currencyPrices = variants.reduce((result, variant) => {
    if (!variant.prices) {
      return result
    }

    const price = variant.prices.find((price) => price.currency_code === currencyCode)
    if (price) {
      result.push(price)
    }

    return result
  }, [] as MoneyAmount[])

  if (!currencyPrices.length) {
    return undefined
  }

  //find the price with the lowest amount in currencyPrices
  const cheapestPrice = currencyPrices.reduce((result, price) => {
    if (result.amount > price.amount) {
      return price
    }

    return result
  })

  return cheapestPrice
}

export const findCheapestPrice = (variants: Variant[], region: Region, countryCode: string) => {
  const { id, currency_code } = region

  let cheapestPrice = findCheapestRegionPrice(variants, id)

  if (!cheapestPrice) {
    cheapestPrice = findCheapestCurrencyPrice(variants, currency_code)
  }

  if (cheapestPrice) {
    return formatAmount({
      amount: cheapestPrice.amount,
      region: region,
      countryCode,
    })
  }

  // if we can't find any price that matches the current region,
  // either by id or currency, then the product is not available in
  // the current region
  return "Not available in your region"
}

export type FormatVariantPriceParams = {
  variant: DetailVariantData
  region: RegionData
  countryCode: string
  addTaxes?: boolean
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  locale?: string
}

/**
 * Takes a product variant and a region, and converts the variant's price to a localized decimal format
 */
export const formatVariantPrice = ({
  variant,
  region,
  countryCode,
  addTaxes = true,
  ...rest
}: FormatVariantPriceParams) => {
  const amount = computeVariantPrice({ variant, region, countryCode, addTaxes })

  return convertToLocale({
    amount,
    currencyCode: region.currency_code,
    countryCode,
    ...rest,
  })
}

type ComputeVariantPriceParams = {
  variant: DetailVariantData
  region: RegionData
  countryCode: string
  addTaxes?: boolean
}

/**
 * Takes a product variant and region, and returns the variant price as a decimal number
 * @param params.variant - product variant
 * @param params.region - region
 * @param params.addTaxes - whether to include taxes or not
 */
export const computeVariantPrice = ({ variant, region, addTaxes = true }: ComputeVariantPriceParams) => {
  const amount = getVariantPrice(variant, region)

  return computeAmount({
    amount,
    region,
    addTaxes,
  })
}

/**
 * Finds the price amount correspoding to the region selected
 * @param variant - the product variant
 * @param region - the region
 * @returns - the price's amount
 */
export const getVariantPrice = (variant: Pick<DetailVariantData, "prices">, region: RegionData) => {
  const price = variant?.prices?.find(
    (prive) => prive.currency_code.toLowerCase() === region?.currency_code?.toLowerCase(),
  )

  return price?.amount || 0
}

export type ComputeAmountParams = {
  amount: number
  region: RegionData
  addTaxes?: boolean
}

/**
 * Takes an amount, a region, and returns the amount as a decimal including or excluding taxes
 */
export const computeAmount = ({ amount, region, addTaxes = true }: ComputeAmountParams) => {
  const price = convertToDecimal(amount)

  const taxRate = addTaxes ? getTaxRate(region) : 0

  const amountWithTaxes = price * (1 + taxRate)

  return amountWithTaxes
}

export type FormatAmountParams = {
  amount: number
  region: RegionData
  countryCode: string
  addTaxes?: boolean
  short?: boolean
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}

/**
 * Takes an amount and a region, and converts the amount to a localized decimal format
 */
export const formatAmount = ({ amount, region, addTaxes = true, ...rest }: FormatAmountParams) => {
  if (!region) {
    throw new Error("A region is required")
  }

  const taxAwareAmount = computeAmount({
    amount,
    region,
    addTaxes,
  })

  return convertToLocale({
    amount: taxAwareAmount,
    currencyCode: region.currency_code,
    ...rest,
  })
}

const convertToDecimal = (amount: number) => {
  return Math.round(amount) / 100
}

export const getTaxRate = (region: RegionData) => {
  return region && !isEmpty(region) ? region?.tax_rate / 100 : 0
}

type ConvertToLocaleParams = {
  amount: number
  currencyCode: string
  countryCode: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  short?: boolean
}

const convertToLocale = ({
  amount,
  currencyCode,
  countryCode,
  minimumFractionDigits,
  maximumFractionDigits,
  short,
}: ConvertToLocaleParams) => {
  const locale = getLocaleForCountryCode(countryCode)

  const formatted = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount)

  if (short) {
    return formatted.replace(/[,.]00/, "")
  }

  return formatted
}

export function roundPrice<T extends number | undefined>(price: T): T {
  if (typeof price === "number") {
    return (Math.round(price * 100) / 100) as T
  }
  return price
}
