export const META_DATA = {
  siteName: "Flowers & Me",
  brandName: "Flowers & Me",
  slogan: "Deine Deko-Box.",
  logo: "/assets/android-chrome-512x512.png",
  origin: "https://flowers-and-me.shop",
  socials: {
    instagram: "https://www.instagram.com/flowers_and_me.shop/",
    facebook: "https://www.facebook.com/people/FlowersMe/61565378204836/",
    tiktok: "https://www.tiktok.com/@flowers.and.me",
    pinterest: "https://www.pinterest.de/flowersandmeshop/",
    youtube: "https://www.youtube.com/@flowersandmeshop",
  },
  address: {
    companyName: "Flowers & Me GmbH",
    street: "Robert-Bosch-Str. 8",
    postalCode: "78549",
    town: "Spaichingen",
    country: "Deutschland",
    countryCode: "DE",
  },
  text: {
    de: {
      slogan: "Alles dabei. Selber gemacht. Wunderschön.",
      description:
        "Freu dich auf eine entspannte Kreativ-Zeit ohne erst alles zusammensuchen zu müssen. Wir legen dir eine bebilderte Schritt-für-Schritt-Anleitung sowie einen Link zu einer Video-Anleitung. So gelingt's garantiert.",
    },
    en: {
      slogan: "Everything you need. Handmade. Beautiful.",
      description:
        "Look forward to a relaxed creative time without having to gather everything first. We provide you with an illustrated step-by-step guide as well as a link to a video tutorial. Guaranteed to succeed.",
    },
  },
}
