function mapDomains(src: string) {
  return src
    .replace(/^\//, "__shop__")
    .replace("__shop__", "https://flowers-and-me.twic.pics/shop/")
    .replace(
      "https://medusa-public-images.s3.eu-west-1.amazonaws.com/",
      "https://flowers-and-me.twic.pics/medusa-public-images/",
    )
    .replace(
      "https://minio-server-a47q.onrender.com/medusa-public-files/",
      "https://flowers-and-me.twic.pics/medusa-public-files/",
    )
}

export type TwicPicsOptions = {
  w?: number
  h?: number
  fit?: "cover" | "contain"
}

export function toImageCdnUrl(src: string, options?: TwicPicsOptions) {
  if (src.startsWith("data:")) {
    return src
  }

  const twicPicsUrl = mapDomains(src)

  // https://www.twicpics.com/docs/reference/transformations
  const transformations: string[] = []

  if (options?.w || options?.h) {
    const tp_resize = options?.fit === "contain" ? "contain" : "cover"
    const tp_width = options?.w || "-"
    const tp_height = options?.h || "-"

    transformations.push(`${tp_resize}=${tp_width}x${tp_height}`)
  }

  return [twicPicsUrl, transformations.join("/")].filter(Boolean).join("?twic=v1/")
}
