export enum Ccm19EmbeddingName {
  // ADCELL = "ADCELL",
  // Builder = "Builder",
  CCM19 = "Cookie Consent Manager CCM19",
  // Crisp = "Crisp Chatbox",
  FlowersAndMe = "Flowers & Me",
  FraudBlocker = "Fraud Blocker",
  GoogleAdsConversion = "Google Ads Conversion Tracking",
  GoogleAdsRemarketing = "Google Ads Remarketing",
  GoogleAnalytics = "Google Analytics 4",
  Klaviyo = "Klaviyo",
  Sentry = "Sentry",
  // Sovendus = "Sovendus",
  // Tracify = "Tracify",
  // TrustedShops = "Trusted Shops",
  // Typeform = "Typeform",
  // Session = "Verwaltung der Session",
  // WooCommerce = "WooCommerce",
  // YouTube = "YouTube Video",
}

export const ccm19DefaultConsent: { [key in Ccm19EmbeddingName]?: boolean } = {
  [Ccm19EmbeddingName.CCM19]: true,
  // [Ccm19EmbeddingName.Crisp]: true,
  [Ccm19EmbeddingName.FlowersAndMe]: true,
  // [Ccm19EmbeddingName.Session]: true,
  // [Ccm19EmbeddingName.Tracify]: true,
}
